import Pager from 'common/components/Pager';
import ResultCount from 'common/components/ResultCount';
import { SortState } from 'common/components/SortFilterResultsTable';
import I18n from 'common/i18n';
import { Schedule, ScheduleTargetType } from 'common/types/schedule';
import React from 'react';
import { SortKey } from 'schedules/main';
import { none, Option, some } from 'ts-option';
import { Dashboard } from './Dashboard';
import SchedulesTable, { getAgents } from './SchedulesTable';

const t = (k: string) => I18n.t(k, { scope: 'schedules' });

const LoadingSchedules = () => (
  <div className="loading-schedules">
    <span className="spinner-default spinner-large"></span>
  </div>
);

interface LoadErrorProps {
  message: string;
}
const LoadError = (props: LoadErrorProps) => (
  <div className="loading-error alert error">
    <span>{props.message}</span>
  </div>
);

export type SchedulesPage = 'gateway' | 'url' | 'all';

type ScheduleProvider = (
  offset: number,
  limit: number,
  filter: Option<string>,
  sort: SortState<SortKey>
) => Promise<{ schedules: Schedule[]; count: number; failures: number }>;

interface ScheduleAdminProps {
  page: Option<SchedulesPage>;
  getSchedules: ScheduleProvider;
}
interface ScheduleAdminState {
  schedules: Schedule[];
  search: Option<string>;
  sort: SortState<SortKey>;

  error: Option<string>;
  page: number;
  count: Option<{ total: number; failures: number }>;
  inProgress: boolean;
}

const PAGE_SIZE = 15;
export default class Schedules extends React.Component<ScheduleAdminProps, ScheduleAdminState> {
  constructor(props: ScheduleAdminProps) {
    super(props);
    this.state = {
      schedules: [],
      search: none,
      sort: {
        by: 'dataset_name',
        direction: 'ASC'
      },
      error: none,
      count: none,
      inProgress: false,
      page: 1 // common pager users 1 based page,
    };
  }

  componentDidMount() {
    this.getSchedules();
  }

  componentDidUpdate(prevProps: ScheduleAdminProps) {
    if (prevProps.page !== this.props.page) {
      this.getSchedules();
    }
  }

  getSchedules = async () => {
    this.setState({ inProgress: true });
    const offset = (this.state.page - 1) * PAGE_SIZE;
    const result = await this.props.getSchedules(offset, PAGE_SIZE, this.state.search, this.state.sort);

    this.setState({
      count: some({ total: result.count, failures: result.failures }),
      schedules: result.schedules,
      inProgress: false
    });
  };

  onChangePage = (newPage: number) => {
    this.setState({ page: newPage }, this.getSchedules);
  };

  onSearch = (search: string) => {
    this.setState({ search: some(search) }, this.getSchedules);
  };

  onSort = async (sort: SortState<SortKey>) => {
    this.setState({ sort }, this.getSchedules);
  };

  render() {
    return (
      <div>
        {this.state.count
          .map((count) => (
            <div>
              <Dashboard
                successes={count.total - count.failures}
                fails={count.failures}
                category={this.props.page.getOrElseValue('all')}
              />
              <SchedulesTable
                getAgents={getAgents}
                inProgress={this.state.inProgress}
                schedules={this.state.schedules}
                onSearchChange={this.onSearch}
                onSortChange={this.onSort}
                search={this.state.search.getOrElseValue('')}
                sort={this.state.sort}
              />
              <div className="counts">
                <Pager
                  changePage={this.onChangePage}
                  currentPage={this.state.page}
                  resultsPerPage={PAGE_SIZE}
                  resultCount={count.total}
                />
                <ResultCount pageNumber={this.state.page} resultsPerPage={PAGE_SIZE} total={count.total} />
              </div>
            </div>
          ))
          .getOrElse(() => {
            return this.state.error.match({
              none: () => <LoadingSchedules />,
              some: (message: string) => <LoadError message={message} />
            });
          })}
      </div>
    );
  }
}
