import Dropdown from 'common/components/Dropdown';
import ScheduleSource from 'common/components/ScheduleModal/components';
import Cron from 'common/components/ScheduleModal/cron';
import * as ScheduleHelpers from 'common/components/ScheduleModal/helpers';
import { IconName, SocrataIcon } from 'common/components/SocrataIcon';
import { StatelessTable, Column, SortState } from 'common/components/SortFilterResultsTable';
import { DsmapiResource, WrappedResource } from 'common/types/dsmapi';
import { Agent } from 'common/types/gateway';
import { Schedule } from 'common/types/schedule';
import { checkStatus, getJson, socrataFetch } from 'datasetManagementUI/lib/http';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';
import * as _ from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import React from 'react';
import { none, Option, some } from 'ts-option';
import I18n from 'common/i18n';
import { SortKey } from 'schedules/main';

const t = (k: string, interpolate: Record<string, string | number> = {}) =>
  I18n.t(k, { ...interpolate, scope: 'schedules' });

const RenderDatasetName = (sched: Schedule) => (
  <a href={`/d/${sched.fourfour}`} target="_blank">
    {sched.dataset_name}
  </a>
);

const RenderConnectionType = (sched: Schedule) => {
  if (sched.action.type === 'connection_agent') {
    return (
      <a href="/admin/gateway" target="_blank">
        {t('gateway')}
      </a>
    );
  }
  if (sched.action.type === 'from_url') {
    return <span>{t('url')}</span>;
  }
  return <span></span>;
};

const RenderConnectionStatus = (sched: Schedule) => {
  if (sched.consecutive_failures > 0) {
    return (
      <span className="last-run last-run-failed">
        <SocrataIcon name={IconName.Failed} />
        {t('last_run_failed')}
      </span>
    );
  }
  if (sched.paused) {
    return (
      <span className="last-run paused">
        <SocrataIcon name={IconName.Warning} />
        {t('paused')}
      </span>
    );
  }
  return (
    <span className="last-run last-run-ok">
      <SocrataIcon name={IconName.Check} />
      {t('last_run_ok')}
    </span>
  );
};

const RenderLastRun = (sched: Schedule) => {
  const lastRun = sched.last_run;
  if (lastRun && sched.consecutive_failures === 0) {
    return <span>{moment(lastRun).format('LLL')}</span>;
  } else if (lastRun && sched.consecutive_failures > 0) {
    return <span className="last-run-failed">{moment(lastRun).format('LLL')}</span>;
  } else {
    return <span>{t('never_run')}</span>;
  }
};

const RenderNextRun = (sched: Schedule) => {
  let next = null;
  if (sched.paused) {
    next = t('paused');
  } else if (sched.paused_due_to_failure) {
    next = t('paused_due_to_failure');
  } else if (sched.runstate.state === 'scheduled') {
    next = moment(sched.runstate.runs_at).format('LLL');
  } else if (sched.runstate.state === 'running') {
    next = t('currently_running');
  }

  return <span>{next}</span>;
};

const RenderCadence = (sched: Schedule) => {
  let humanized = '';
  if (sched.cadence.cron) {
    const timezone = sched.cadence.cron_in_timezone && sched.cadence.timezone ? `(${momentTimezone.tz(sched.cadence.timezone).zoneAbbr()})` : '(UTC)';
    humanized = `${new Cron(sched.cadence.cron).humanize()} ${timezone}`;
  } else if (_.isNumber(sched.cadence.interval_minutes)) {
    const duration = moment.duration(sched.cadence.interval_minutes, 'minutes');
    // // moment doesn't have flexible duration formatting functionality...ffs
    // // https://github.com/moment/moment/issues/463
    const units = ['years', 'months', 'days', 'hours', 'minutes'];
    const every = (label: string) => {
      const quantity = duration[label]();
      if (quantity === 0) {
        return undefined;
      } else if (quantity === 1) {
        return t(`durations.${label}.single`, { n: quantity });
      } else {
        return t(`durations.${label}.plural`, { n: quantity });
      }
    };
    humanized = t('durations.every', {n: _.compact(units.map(every)).join(', ')});
  }
  return <span>{humanized}</span>;
};

interface ScheduleOption {
  value: (s: Schedule) => void;
}
const renderEditDropdown =
  (showEditModal: (s: Schedule) => void, runNow: (s: Schedule) => void) => (sched: Schedule) => {
    return (
      <Dropdown
        placeholder={() => (
          <button className="btn btn-default">
            <SocrataIcon name={IconName.Kebab} />
          </button>
        )}
        picklistSizingStrategy={'EXPAND_TO_WIDEST_ITEM'}
        onSelection={(option: ScheduleOption) => option.value(sched)}
        options={[
          { title: t('edit'), value: showEditModal },
          { title: t('run_now'), value: runNow }
        ]}
      />
    );
  };

export const getAgents = (): Promise<DsmapiResource<Agent>[]> =>
  socrataFetch(dsmapiLinks.agentIndex).then(checkStatus).then(getJson);

interface SchedulesTableProps {
  schedules: Schedule[];
  search: string;
  onSearchChange: (f: string) => void;
  sort: SortState<SortKey>;
  onSortChange: (s: SortState<SortKey>) => void;
  inProgress: boolean;
  getAgents: () => Promise<DsmapiResource<Agent>[]>;
}

interface SchedulesTableState {
  modalSchedule: Option<Schedule>;
  agents: Agent[];
  onRunComplete: Option<() => void>;
}
export default class SchedulesTable extends React.Component<SchedulesTableProps, SchedulesTableState> {
  constructor(props: SchedulesTableProps) {
    super(props);
    this.state = {
      agents: [],
      modalSchedule: none,
      onRunComplete: none
    };
    this.showEditModal = this.showEditModal.bind(this);
    this.runNow = this.runNow.bind(this);
  }
  componentDidMount() {
    this.props.getAgents().then((resources) => {
      this.setState({ agents: resources.map((r) => r.resource) });
    });
  }

  showEditModal(s: Schedule) {
    this.setState({ modalSchedule: some(s), onRunComplete: none });
  }

  runNow(s: Schedule) {
    ScheduleHelpers.runNow(s)
      .ok((runningSchedule) => {
        this.setState({
          modalSchedule: some(runningSchedule),
          onRunComplete: some(() => this.setState({ modalSchedule: none }))
        });
      })
      .run();
  }

  render() {
    const tz = momentTimezone.tz.guess();
    const tzAbbr = momentTimezone.tz(tz).zoneAbbr();
    return (
      <div>
        <StatelessTable
          items={this.props.schedules}
          searchPlaceholder={t('search')}
          rowKey={'fourfour'}
          rowClassFunction={() => 'schedule'}
          id={'schedules'}
          noResultsMessage={t('no_schedules')}
          search={this.props.search}
          onSearchChange={this.props.onSearchChange}
          sort={this.props.sort}
          onSortChange={this.props.onSortChange}
          inProgress={this.props.inProgress}
        >
          <Column<Schedule, SortKey>
            header={t('dataset_name')}
            sortKey={'dataset_name'}
            render={RenderDatasetName}
          />
          <Column<Schedule, SortKey>
            header={t('connection_type')}
            sortKey={'connection_type'}
            render={RenderConnectionType}
          />
          <Column<Schedule, SortKey>
            header={t('connection_status')}
            sortKey={'connection_status'}
            render={RenderConnectionStatus}
          />
          <Column<Schedule, SortKey>
            header={`${t('last_run')} (${tzAbbr})`}
            sortKey={'last_run'}
            render={RenderLastRun}
          />
          <Column<Schedule, SortKey>
            header={`${t('next_run')} (${tzAbbr})`}
            sortKey={'next_run'}
            render={RenderNextRun}
          />
          <Column<Schedule, SortKey> header={t('schedule_frequency')} render={RenderCadence} />
          <Column<Schedule, never>
            header={t('actions')}
            render={renderEditDropdown(this.showEditModal, this.runNow)}
          />
        </StatelessTable>

        {this.state.modalSchedule
          .map((s: Schedule) => (
            <ScheduleSource
              fourfour={s.fourfour}
              onScheduleCompleted={() => {
                this.state.onRunComplete.map((f) => f());
              }}
              scheduleProvider={() => new WrappedResource(s)}
              automationParams={ScheduleHelpers.scheduleToAutomationParams(s, this.state.agents)}
              onDismiss={() => this.setState({ modalSchedule: none })}
            />
          ))
          .getOrElseValue(<div></div>)}
      </div>
    );
  }
}
