import _ from 'lodash';

const base = '/api/publishing/v1';

export const scheduleIndex = () => `${base}/schedule`;

export const schedule = params => `${scheduleIndex()}/${params.fourfour}`;

export const config = params => `${base}/revision/${params.fourfour}/config`;

const revisionsForView = () => `${base}/revision/${_.get(window, 'initialState.view.id', '')}`;

// TODO: change callers of this to pass revisionSeq
export const revisionBase = params => `${revisionsForView()}/${params.revisionSeq}`;
export const revisionPlan = params => `${revisionBase(params)}/plan`;

export const addAttachment = revision => `${revisionsForView()}/${revision.revision_seq}/attachment`;

export const sourceIndex = params => `${revisionBase(params)}/source`;
export const sourceCreate = sourceIndex;

export const initiateUpload = sourceId => `${sourceBytes(sourceId)}/initiate`;
export const sourceBytes = sourceId => `${base}/source/${sourceId}`;
export const startSourceAgent = (sourceId) => `${sourceBytes(sourceId)}/agent`;
export const sourceShow = sourceBytes;
export const sourceUpdate = sourceId => `${sourceBytes(sourceId)}/options`;

export const loadData = sourceId => `${base}/source/${sourceId}/load`;

// TODO: find names controller uses!
export const newOutputSchema = (sourceId, inputSchemaId, force) => {
  let maybeForce = '';
  if (force) {
    maybeForce = '?force=true';
  }
  return `${base}/source/${sourceId}/schema/${inputSchemaId}${maybeForce}`;
};

export const applyRevision = params => `${revisionBase(params)}/apply`;

export const columnErrors = (sourceId, inputSchemaId, outputSchemaId, columnId, limit, offset) =>
  `${base}/source/${sourceId}/schema/${inputSchemaId}/errors/${outputSchemaId}` +
  `?limit=${limit}&offset=${offset}&column_id=${columnId}`;

export const rowErrors = (sourceId, inputSchemaId, limit, offset) =>
  `${base}/source/${sourceId}/schema/${inputSchemaId}/errors?limit=${limit}&offset=${offset}`;

export const rows = (sourceId, inputSchemaId, outputSchemaId, limit, offset) =>
  `${base}/source/${sourceId}/schema/${inputSchemaId}/rows/${outputSchemaId}` +
  `?limit=${limit}&offset=${offset}`;

export const columnRows = (sourceId, transformId, limit, offset) =>
  `${base}/source/${sourceId}/transform/${transformId}/results` +
  `?limit=${limit}&offset=${offset}`;

export const updateCell = (sourceId, transformId, offset) => (
  `${base}/source/${sourceId}/transform/${transformId}/results/${offset}`
);

export const errorExport = (sourceId, inputSchemaId, outputSchemaId) =>
  `${base}/source/${sourceId}/schema/${inputSchemaId}/errors/${outputSchemaId}`;

export const rowsExport = (sourceId, inputSchemaId, outputSchemaId) =>
  `${base}/source/${sourceId}/schema/${inputSchemaId}/rows/${outputSchemaId}?validity=valid`;


export const validateRowIdentifier = (sourceId, transformId) =>
  `${base}/source/${sourceId}/transform/${transformId}/validate_row_identifier`;

export const createImportConfig = outputSchemaId => `${base}/config/build_from/${outputSchemaId}`;

export const exportSource = (revisionSeq, sourceId) =>
  `${revisionBase({ revisionSeq })}/source/download/${sourceId}`;

export const scanResult = (revisionSeq, sourceId) =>
  `${revisionBase({ revisionSeq })}/source/scan_result/${sourceId}`;

export const cancelSource = (sourceId) => `${base}/source/${sourceId}/cancel`;

export const agentIndex = `${base}/connection_agent`;

export const currentAgentVersion = () => `${agentIndex}/version`;

export const showAgent = uid => `${agentIndex}/${uid}`;

export const provisionAgent = `${agentIndex}/launcher`;

export const pluginTypeIndex = `${base}/connection_agent/plugin`;

export const chunkBytes = (sourceId, seqNo, offset) =>
  `${base}/source/${sourceId}/chunk/${seqNo}/${offset}`;

export const chunkCommit = (sourceId, endingSeqNo, filesize) =>
  `${chunkBytes(sourceId, endingSeqNo, filesize)}/commit`;
