import type { ServerConfig } from 'common/types/windowSocrata';
import airbrake from 'common/airbrake';
import { SortState } from 'common/components/SortFilterResultsTable';
import { buildQueryString, checkStatus, fetchWithDefaultHeaders } from 'common/http';
import { DsmapiResource } from 'common/types/dsmapi';
import { Schedule } from 'common/types/schedule';
import * as dsmapiLinks from 'datasetManagementUI/links/dsmapiLinks';
import * as _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { browserHistory, Route, Router } from 'react-router';
import { none, Option, option, some } from 'ts-option';
import Schedules from './components/Schedules';

export type SortKey = 'dataset_name' | 'connection_type' | 'connection_status' | 'last_run' | 'next_run';

const getSchedules =
  (type: Option<string>) =>
  async (
    offset: number,
    limit: number,
    filter: Option<string>,
    sort: SortState<SortKey>
  ): Promise<{ schedules: Schedule[]; count: number; failures: number }> => {
    const params: Record<string, string | number> = {
      offset,
      limit,
      sort: sort.by,
      direction: sort.direction
    };

    filter
      .map((f) => f.trim())
      .filter((f) => f !== '')
      .forEach((f) => (params.dataset_name = f));

    type
      .flatMap((t) => {
        if (t === 'gateway') return some('connection_agent');
        if (t === 'url') return some('from_url');
        return none;
      })
      .forEach((t) => (params.type = t));

    return await fetchWithDefaultHeaders(`${dsmapiLinks.scheduleIndex()}?${buildQueryString(params)}`)
      .then(checkStatus)
      .then(async (response: Response) => {
        const count = _.toNumber(response.headers.get('x-total-count')) || 0;
        const failures = _.toNumber(response.headers.get('x-failure-count')) || 0;
        const schedules: DsmapiResource<Schedule>[] = await response.json();
        return { count, failures, schedules: schedules.map((s) => s.resource) };
      });
  };

interface RouterParams {
  params: {
    [name: string]: string;
  };
}

declare global {
  interface Window {
    serverConfig?: ServerConfig;
  }
}

airbrake.init(window.serverConfig?.airbrakeProjectId, window.serverConfig?.airbrakeKey);

const SchedulesWrapper = (props: RouterParams) => {
  const page = option(_.get(props, 'params.kind'));
  return <Schedules page={page} getSchedules={getSchedules(page)} />;
};

ReactDOM.render(
  <Router history={browserHistory}>
    <Route path="admin/schedules" component={SchedulesWrapper} />
    <Route path="admin/schedules/:kind" component={SchedulesWrapper} />
  </Router>,
  document.querySelector('#schedules-main')
);
