import React from 'react';
import { Schedule } from 'common/types/schedule';
import { Link } from 'react-router';
import I18n from 'common/i18n';
const t = (k: string) => I18n.t(k, { scope: 'schedules' });

interface SchedulesDashboardProps {
  fails: number;
  successes: number;
  category: 'all' | 'gateway' | 'url';
}

type StatState = 'success' | 'error' | 'warn' | undefined;
interface StatProps { name: string; count: number; state: StatState }
function Stat(props: StatProps) {
  return (
    <div className="dash-statistic">
      <p className={`dash-statistic-count dash-statistic-${props.state || ''}`}>{props.count}</p>
      <p className="dash-statistic-label">{props.name}</p>
    </div>
  );
}

export function Dashboard(props: SchedulesDashboardProps) {
  const { successes, fails } = props;
  const cat = props.category;
  return (
    <div className="schedules-dashboard">

      <ul className="nav-tabs">
        <li className={`tab-link ${cat === 'all' ? 'current' : ''}`}>
          <Link to="/admin/schedules">{t('all_schedules')}</Link>
        </li>
        <li className={`tab-link ${cat === 'gateway' ? 'current' : ''}`}>
          <Link to="/admin/schedules/gateway">{t('gateway_schedules')}</Link>
        </li>
        <li className={`tab-link ${cat === 'url' ? 'current' : ''}`}>
          <Link to="/admin/schedules/url">{t('url_schedules')}</Link>
        </li>
      </ul>

      <div className="admin-dashboard schedules-stats">
        <div className="dash-group">
          <Stat state={'success'} name={t('successful')} count={successes} />
          <Stat state={'error'} name={t('failed')} count={fails} />
        </div>
      </div>
    </div>
  );
}
